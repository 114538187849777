@use '../styles/_variables' as *

.salesRespresentativesMap

	&-map
		margin-top: 30px
		position: relative
		min-height: 50vh
		max-height: 75vh

		&::before
			content: ''
			display: block
			padding-top: calc(100% / (1440 / 620))

		&-in
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0

			.gm-style-iw
				padding: 0
				border-radius: 0 !important
				background-color: transparent
				border: none !important

				& > div
					overflow: initial !important

				& > button
					display: none !important
