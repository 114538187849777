@use '../styles/_variables' as *

.productVariant
	display: flex
	align-items: center
	line-height: 1
	white-space: nowrap
	text-align: left
	position: relative
	
	&-info
		padding-top: 8px 
		padding-bottom: 8px

	&-icon
		position: relative
		width: 91px
		min-height: 44px
		height: 100%
		margin-right: 12px
		//border-radius: 50%
		overflow: hidden
		background-color: #DADADA
		flex-shrink: 0

		@media screen and (max-width: $content-width-narrow)
			width: 50px
			margin-right: 6px

			span 
				height: 44px !important


	&-name
		font-size: 15px
		font-weight: $font-weight-book

	&-description
		font-weight: $font-weight-book
		font-size: 12px

	&-name,
	&-description

		// Reserve width
		&-possibility
			height: 0
			opacity: 0
			overflow: hidden

			&::before
				content: attr(data-content)

	&-price
		font-size: 13px
		font-weight: $font-weight-book
		margin-left: 0.75em
		flex-grow: 1
		text-align: right

	&-unit
		font-size: 13px
		font-weight: $font-weight-book
		flex-grow: 1
		text-align: right
