@use '../styles/_variables' as *

.intro
	$root: &
	$break800: 800px

	--intro-bottom-space: 20px
	z-index: 10

	&.has-lead
		--intro-bottom-space: 40px

	&-in
		z-index: 10
		padding-top: 10px
		padding-bottom: var(--intro-bottom-space)
		text-align: center

		@media (min-width: $content-width-narrow)
			padding-top: 40px
			--intro-bottom-space: 40px

			&.has-lead
				--intro-bottom-space: 70px

		> #{$root}-additionalInfo
			@media (min-width: $content-width-narrow)
				padding-bottom: 35px

	&-title
		text-transform: uppercase
		font-size: 31px
		font-family: $font-head
		margin: 0 0 15px

		@media (min-width: $break800)
			font-size: 49px

	&-subtitle
		//

	&-lead
		margin-top: 0.5em

	&-additionalInfo
		margin-top: 1.5em
		font-size: 15px
		font-weight: $font-weight-book
		color: #AAAAAA

		&-separator
			margin: 0 0.5em

	&-image
		margin-bottom: 2em

		@media (min-width: $break800)
			overflow: hidden
			border-radius: $border-radius

	&-content
		display: grid
		grid-auto-rows: min-content
		gap: 0 $page-horizontal-spacing
		grid-template-columns: minmax(0, auto) minmax(0, #{$content-width-narrow}) minmax(0, auto)

		@media (max-width: $content-width-small)
			grid-template-columns: 20px minmax(0, #{$content-width-small}) 20px


		> *
			grid-column: 2

	&-lead
		grid-column: 1 / -1

	&.view-withImage &-in
		max-width: $content-width + 2 * $page-horizontal-spacing
		margin-left: auto
		margin-right: auto

		@media (min-width: $break800)
			display: grid
			grid-template-columns: minmax(350px, 1fr) minmax(200px, 560px)

			#{$root}-title
				max-width: $content-width-narrow
				margin-left: auto
				margin-right: auto
				width: 100%

			#{$root}-image
				margin-left: 3.5em
				margin-right: $page-horizontal-spacing
				margin-bottom: 0
				order: 1 // Last
				align-self: start

			#{$root}-content
				text-align: left
				grid-template-columns: minmax(0, auto) 1fr minmax(0, auto)

	@media print 
		.content
			display: none

		.intro-content
			grid-template-columns: 1fr

			.intro-title
				grid-column: 1
				text-align: left
				margin-left: 20px
