@use '../styles/_variables' as *

.homepageTilesGrid
	$break: 1000px
	$gap: 2px

	max-width: $content-width-wide + 2 * $page-horizontal-spacing
	width: 100%
	margin: 0 auto $gap
	display: grid
	gap: $gap
	grid-template: 'a a' 'b c' 'd e' / 1fr 1fr

	@media (min-width: $break)
		grid-template: 'a b b c' 'a d e e' / 24fr 17fr 14fr 17fr

	&-item
		display: flex
		overflow: hidden

		&::after
			// Force min height with aspect ratio 1:1
			content: ''
			padding-top: 100%

		&-in
			position: relative
			background-color: #E4E4E4

		&::after,
		&-in
			flex-shrink: 0
			width: 100%

		&:nth-child(1)
			grid-area: a

			&::after
				display: none

		&:nth-child(2)
			grid-area: b

		&:nth-child(3)
			grid-area: c

		&:nth-child(4)
			grid-area: d

		&:nth-child(5)
			grid-area: e

		@media (min-width: $break)
			&:nth-child(2),
			&:nth-child(5)
				&::after
					display: none
