@use '../node_modules/normalize.css/normalize.css'
@use '_global'
@use 'fonts'

@use '../node_modules/react-image-lightbox/style.css'
@use '../node_modules/react-toastify/dist/ReactToastify.css'

@use '../components/alert'
@use '../components/arrow'
@use '../components/articleList'
@use '../components/articleTile'
@use '../components/attributes'
@use '../components/band'
@use '../components/barelyVisibleTitle'
@use '../components/breadcrumbs'
@use '../components/button'
@use '../components/card'
@use '../components/cardBox'
@use '../components/cardBoxGrid'
@use '../components/cardDownload'
@use '../components/cardPicture'
@use '../components/categoriesSwiper'
@use '../components/categoryImage'
@use '../components/categoryList'
@use '../components/categoryTag'
@use '../components/checkbox'
@use '../components/checkboxList'
@use '../components/collapsible'
@use '../components/collapsibleList'
@use '../components/companiesList'
@use '../components/companyDetail'
@use '../components/composition'
@use '../components/compositionsLayout'
@use '../components/contactForm'
@use '../components/contactInfoLayout'
@use '../components/vendorLocationSearchBox'
@use '../components/contained'
@use '../components/content'
@use '../components/cookieConsent'
@use '../components/cross'
@use '../components/departmentList'
@use '../components/documents'
@use '../components/errorNotice'
@use '../components/fencePostCapsConfigurator'
@use '../components/filter'
@use '../components/filters'
@use '../components/footer'
@use '../components/footnote'
@use '../components/fulltextHitGroup'
@use '../components/fulltextHits'
@use '../components/gallery'
@use '../components/galleryGrid'
@use '../components/galleryMasonry'
@use '../components/garden'
@use '../components/hamburgerButton'
@use '../components/header'
@use '../components/helpSubtitle'
@use '../components/hidingHeader'
@use '../components/highlightedBox'
@use '../components/homepageTilesGrid'
@use '../components/horizontalLine'
@use '../components/input'
@use '../components/inquiryForm'
@use '../components/interactiveHero'
@use '../components/intervalSlider'
@use '../components/intro'
@use '../components/languagePicker'
@use '../components/layout'
@use '../components/leadText'
@use '../components/lightTabs'
@use '../components/linkBoxes'
@use '../components/linkSlider'
@use '../components/mainContacts'
@use '../components/mapSearch'
@use '../components/modal'
@use '../components/news'
@use '../components/numberedColumns'
@use '../components/openingHours'
@use '../components/pageTransitionIndicator'
@use '../components/pagination'
@use '../components/parameters'
@use '../components/picture'
@use '../components/popup'
@use '../components/printButton'
@use '../components/exportButton'
@use '../components/productLayout'
@use '../components/productList'
@use '../components/productListRow'
@use '../components/productRackList'
@use '../components/productRackMap'
@use '../components/productVariant'
@use '../components/productVariantPicker'
@use '../components/productVariantPickerExpanded'
@use '../components/quantityHeader'
@use '../components/quantityInput'
@use '../components/questionForm'
@use '../components/quickLinks'
@use '../components/radioButtons'
@use '../components/react-tooltip'
@use '../components/references'
@use '../components/regionMap'
@use '../components/removeFromShoppingListButton'
@use '../components/representativesGrid'
@use '../components/salesRespresentativesMap'
@use '../components/searchResultsTitle'
@use '../components/share'
@use '../components/shareLink'
@use '../components/shareShoppingListButton'
@use '../components/shareShoppingListForm'
@use '../components/shoppingListLayout'
@use '../components/shoppingListLink'
@use '../components/showroomLayout'
@use '../components/spacer'
@use '../components/subMenuDesktop'
@use '../components/subMenuDetail'
@use '../components/subMenuMobile'
@use '../components/swimmingPoolConfiguratorLayout'
@use '../components/staircaseAssemblyConfiguratorLayout'
@use '../components/staircaseStepsConfiguratorLayout'
@use '../components/swiper'
@use '../components/tab'
@use '../components/tabs'
@use '../components/tabsPanel'
@use '../components/textColumns'
@use '../components/textWithImage'
@use '../components/Toastify'
@use '../components/topProduct'
@use '../components/topProducts'
@use '../components/vatHeader'
@use '../components/widthBound'
@use '../components/viewport3d'
@use '../components/wysiwyg'
@use '../components/videoArticle'
@use '../components/videoList'
@use '../components/videoTiles'

@use '../components/search/searchResults'
@use '../components/search/navSearch'
@use '../components/search/homepageSearch'

@media print 

	html, body 
		width: 1268px
		height: 885px

		