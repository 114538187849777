@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.documents
	text-align: left

	&-list 
		padding: 0
		margin: 0

	&-lists,
	&-categories
		margin: 0
		padding: 0
		list-style-type: none

	&-title
		&::after
			content: ''
			display: none
			height: 1px
			background-color: #C5C5C5
			flex-grow: 1
			order: 3
			margin-left: 0.5em

	&-title,
	&-category-title
		font-family: $font-base
		font-weight: $font-weight-heavy
		font-size: 18px
		display: flex
		align-items: center
		margin: 0
		scroll-margin-top: 2em

		&::before
			content: ''
			width: 0.5em
			height: 0.5em
			border-bottom: 1px solid
			border-right: 1px solid
			color: #C5C5C5
			order: 2
			transform-origin: 100% 100%
			transform: translateY(-50%) rotate(-45deg)
			margin-left: 0.5em

		&-in
			padding: 0
			border: none
			color: inherit
			text-decoration: none
			background-color: transparent

			&:hover,
			&:focus
				text-decoration: underline

	&-group

		& + &
			margin-top: 50px

	&-group.is-expanded &-title,
	&-categories.is-expanded &-category-title
		&::after
			display: block

		&::before
			display: none

	&-categories
		//

	&-category
		&-title
			margin: 48px 0 0
			font-size: 15px
			font-family: $font-base
			font-weight: $font-weight-heavy

	&-items
		margin-top: 18px
		display: grid
		column-gap: 30px
		row-gap: 15px
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))

		&:empty
			display: none

	&-item 
		.card-in
			padding: 2px calc(var(--card-horizontal-padding) / 2.0)

		.card
			&::after 
				content: attr(data-download-text)
				position: absolute
				bottom: 2px
				line-height: 1rem
				left: 0
				opacity: 0
				transition: opacity 200ms
				font-size: 0.85rem
				font-weight: 300
				text-align: center
				width: 70px

			.cardDownload-icon-png, .cardDownload-icon-svg
					img
						filter: none
						transition: filter 200ms

		.card:hover 
			border: solid $color-primary 1px
			font-weight: bold 
			color: $color-primary
			cursor: pointer

			.cardDownload-icon-png, .cardDownload-icon-svg
				img
					filter: brightness(0)

			&::before
				content: ""
				+position.absolute(0)
				background-color: #259b44
				opacity: 0.16
				z-index: 2
				pointer-events: none

			&::after 
				opacity: 1