@use '../styles/variables' as *
@use '../styles/sass-base/mixins/position'

.button
	$root: &

	overflow-x: hidden
	display: inline-block

	&-in
		min-height: 50px
		border-radius: $border-radius
		border-width: 1px
		border-style: solid
		display: inline-flex
		width: 100%
		justify-content: center
		align-items: center
		font-size: 15px
		cursor: pointer
		padding: 7px 32px
		text-decoration: none

		&[disabled]
			opacity: 0.3
			pointer-events: none
			width: auto

			&:is(.warning-content button)
				opacity: 1
				background-color: rgba($color-primary, 0.3)
				border-color: rgba($color-primary, 0.3)

				.button-content

					>span
						display: flex 
						align-items: center

					em
						font-style: normal 
						// color: $color-text-inverted-bland
						// background-color: $color-background-contained
						display: inline-block
						line-height: 1em
						padding: 5px
						border-radius: $border-radius
						margin-left: 1em

	&-icon
		padding-top: 1px
		padding-right: 7px

	&-arrow
		//

	&-content
		display: flex
		flex-direction: column

		[aria-hidden]
			content: attr(data-content)
			font-weight: 700
			height: 0
			overflow: hidden
			visibility: hidden

	&.is-disabled
		cursor: not-allowed

		& .warning-content
			font-size: 11px
			text-align: left
			color: $color-text-light
			display: grid

			& button 
				margin-bottom: 5px

	&.is-loading 
		border-radius: 4px
		position: relative

		&::before 
			content: ""
			height: 8px 
			position: absolute
			bottom: 0
			left: 0
			width: 100% 
			background-color: $color-primary
			z-index: 2

		&::after 
			@keyframes cardDownload-progress
				0%
					transform: translateX(-200%)
				50%
					transform: translateX(0%) scaleX(0.5)
				100%
					transform: translateX(200%)

			content: ""
			height: 7px 
			position: absolute
			bottom: 1px
			left: 0	
			width: 50% 
			z-index: 5
			background-color: $color-primary-alternative
			animation: cardDownload-progress 0.75s linear infinite
			

	& .alt-content
		text-align: right 

		.alt 
			position: relative
			top: 5px
			float: right 
			left: -2px
			font-size: 13px
			font-weight: 700 
			color: $color-primary
			overflow: hidden


	&.view-default &-in
		background-color: $color-primary
		border-color: $color-primary
		color: $color-text-inverted
		font-weight: 700
		transition: background-color 0.2s, border-color 0.2s

		&:hover 
			background-color: $color-primary-alternative
			border-color: $color-primary-alternative

	&.view-outline &-in
		background-color: transparent
		border-color: $color-primary
		color: $color-primary
		font-weight: 700

	&.view-tabs &-in
		background-color: #e7e7e7
		border-color: #979797
		color: $color-text
		opacity: .6

	&.view-tabs.is-active &-in
		+position.relative()

		border-color: $color-primary
		color: $color-primary
		font-weight: 700
		opacity: 1
		background-color: transparent

		&::before
			content: ""
			+position.absolute(0)
			background-color: #259b44
			opacity: 0.16

		& #{$root}-icon
			color: $color-primary

	&.view-block
		display: block
		overflow-y: hidden

