@use '../styles/_variables' as *

.contactForm
	$gap: 20px

	width: 100%
	display: grid
	gap: $gap

	&-contactForm
		//

	&-field
		//

	&-submit
		//

	&-recaptcha
		display: flex
		justify-content: center

		@media screen and (max-width: $content-width-narrow)
			min-height: 100px
			&>div
				position: absolute

	&-group
		display: grid
		gap: $gap

		@media (min-width: 800px)
			grid-template-columns: 1fr 1fr

