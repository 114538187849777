@use '../styles/_variables' as *
@use '../styles/sass-base/mixins/position'

.quickLinks
	position: relative
	background-color: #F2F2F2

	&-in
		display: flex
		flex-wrap: wrap
		max-width: $content-width-wide + 2 * $page-horizontal-spacing
		width: 100%
		margin: 0 auto

	&-item
		position: relative
		flex-grow: 1
		flex-basis: 0
		min-width: 200px
		background-color: $color-primary
		color: $color-text-inverted
		font-size: 15px
		font-weight: 800
		text-decoration: none
		display: block
		padding: 40px $page-horizontal-spacing
		text-align: center
		z-index: 0

		&:hover,
		&:focus
			text-decoration: underline

		&::before
			content: ''
			display: inline-block
			width: $arrow-width-and-space

		&-icon
			+position.absolute(0)
			display: flex
			justify-content: center
			align-items: center
			z-index: -1
			opacity: 0.15
			color: #262626

		&:hover &,
		&:focus &
			&-icon
				opacity: 0.25

		& + &
			background-color: $color-primary-alternative

	@media print
		display: none
